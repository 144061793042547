import React from "react";
import styled from "styled-components";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Wrapper from "../../elements/wrapper";
import { media, TransitionMixin } from "../../helpers";

import MailIcon from "../../../images/mail.inline.svg";
import LinkedInIcon from "../../../images/linkedin.inline.svg";

const LeadershipSectionContainer = styled.div`
  padding: 0 0 100px;
  margin: 0 auto;
  ${media.medium`max-width: 800px;`}
  ${media.large`max-width: 900px;`}
  .title-container {
    text-align: center;

    h2 {
      color: #222;
      font-weight: 900;
      font-size: 32px;
      text-transform: uppercase;
      ${media.medium`font-size: 42px;`}
    }

    .content {
      text-align: left;
      font-weight: 300;
      font-size: 16px;

      p {
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .profiles-container {
    padding-top: 50px;
    ${media.medium`display: flex;`}

    .item-container {
      margin-bottom: 70px;
      flex: 1;
      ${media.medium`margin-bottom: 0px;`}
      &:last-child {
        margin-bottom: 0px;
      }
      > .inner-wrap {
        max-width: 90%;
        margin: 0 auto;

        .image-container {
          max-width: 200px;
          margin: 0 auto;

          img {
            border-radius: 50%;
          }
        }

        .content-container {
          padding-top: 15px;
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: center;
            li {
              display: inline-block;
              margin-right: 10px;

              a {
                color: #000;
                ${TransitionMixin(".25s")}
                &.mail {
                  position: relative;
                  top: 1px;
                }

                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
          .inner-wrap {
            h4 {
              font-size: 21px;
              font-weight: 900;
              text-transform: uppercase;
              text-align: center;
              ${media.medium`font-size: 34px;`}
            }

            .description {
              font-weight: 300;
              font-size: 16px;
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
`;

const LeadershipSection = ({ settings }) => {
  const { sectionTitle, leadershipContent, leadershipProfiles } =
    settings || {};

  return (
    <LeadershipSectionContainer>
      <Wrapper className="leadership-wrapper">
        <Wrapper className="title-container">
          <h2>{sectionTitle}</h2>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: leadershipContent }}
          ></div>
        </Wrapper>
        <div className="profiles-container">
          {leadershipProfiles.map(profile => {
            const {
              profileName,
              profileImage,
              profileDescription,
              email,
              linkedin,
            } = profile || {};

            let image = getImage(profileImage?.localFile);

            return (
              <div className="item-container">
                <div className="inner-wrap">
                  <div className="image-container">
                    <GatsbyImage
                      alt={profileName + `profile picture.`}
                      image={image}
                    />
                  </div>
                  <div className="content-container">
                    <div className="inner-wrap">
                      <h4>{profileName}</h4>
                      <ul>
                        {email && (
                          <li>
                            <a className="mail" href={`mailto:${email}`}>
                              <MailIcon />
                            </a>
                          </li>
                        )}
                        {linkedin && (
                          <li>
                            <a target="_blank" href={linkedin}>
                              <LinkedInIcon />
                            </a>
                          </li>
                        )}
                      </ul>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: profileDescription,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </LeadershipSectionContainer>
  );
};

export default LeadershipSection;
