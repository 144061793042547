import React from "react";
import styled from "styled-components";
import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const TopContentSectionContainer = styled.div`
  padding: 70px 0 100px;

  .top-content-wrapper {
    ${media.medium`max-width: 800px;`}
    ${media.large`max-width: 900px;`}

    .title-container {
      text-align: center;

      h1 {
        color: #222;
        font-weight: 900;
        font-size: 32px;
        text-transform: uppercase;
        ${media.medium`font-size: 42px;`}
      }

      .content {
        text-align: left;
        font-weight: 300;
        font-size: 16px;
      }
    }
  }
`;

const TopContentSection = ({ settings }) => {
  const { sectionTitle, sectionDescription } = settings || {};
  return (
    <TopContentSectionContainer>
      <Wrapper className="top-content-wrapper">
        <div className="title-container">
          <h1>{sectionTitle}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: sectionDescription }}
          ></div>
        </div>
      </Wrapper>
    </TopContentSectionContainer>
  );
};

export default TopContentSection;
