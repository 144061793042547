import React from "react";
import styled from "styled-components";

import TopContentSection from "./top-content-section";
import LeadershipSection from "./leadership-section";
import Offerings from "./offerings";

const AboutContainer = styled.div``;

const About = ({ data }) => {
  let page = data?.about?.edges[0]?.node || {};

  let { firstContentSection, leadershipSection, whatWeOfferSection } =
    page?.about_acf || {};
  console.log("about", page);
  return (
    <AboutContainer>
      <TopContentSection settings={firstContentSection} />
      {leadershipSection && <LeadershipSection settings={leadershipSection} />}
      <Offerings settings={whatWeOfferSection} />
    </AboutContainer>
  );
};

export default About;
