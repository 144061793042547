import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Wrapper from "../../elements/wrapper";
import { media } from "../../helpers";

const OfferingsContainer = styled.div`
  padding-bottom: 100px;
  .title-container {
    text-align: center;
    padding-bottom: 100px;
    ${media.medium`max-width: 800px;`}
    ${media.large`max-width: 900px;`}

    &.no-cap {
      padding-bottom: 30px;
    }
    h2 {
      color: #222;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 32px;
      ${media.medium`font-size: 42px;`}
    }

    .offering-description {
      text-align: left;
      font-weight: 300;

      p {
        font-size: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
  .offerings-container {
    ${media.large`max-width: 1180px;`}

    .row {
      margin: 0 -15px;
      ${media.medium`display: flex; flex-wrap: wrap; justify-content: center;`}

      .item-container {
        flex: 0 0 50%;
        margin-bottom: 30px;
        padding: 0 15px;
        .inner-wrap {
          .image-container {
            position: relative;

            .gatsby-image-wrapper {
              max-height: 270px;
              overflow: hidden;
              ${media.large`max-height: 350px;`}
            }
            .overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgb(0 0 0 / 50%);
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .image-title {
              h2 {
                color: #fff;
                margin-bottom: 0px;
                text-transform: uppercase;
                font-weight: 900;
                font-size: 42px;
                border: 4px solid #fff;
                padding: 10px 0;
                min-width: 340px;
                text-align: center;
              }
            }
          }
          .content-container {
            font-weight: 300;
            font-size: 15px;
            line-height: 1.4;
            padding: 10px 0 0;
            text-align: center;
          }
        }
      }
    }
  }
`;

const Offerings = ({ settings, caption }) => {
  const { sectionTitle, sectionDescription, offerings } = settings || {};
  return (
    <OfferingsContainer>
      <Wrapper
        className={caption ? "title-container no-cap" : "title-container"}
      >
        <h2>{sectionTitle}</h2>
        <div
          className="offering-description"
          dangerouslySetInnerHTML={{ __html: sectionDescription }}
        ></div>
      </Wrapper>
      <Wrapper className="offerings-container">
        <div className="row">
          {offerings &&
            offerings.map((offering, offeringIndex) => {
              const {
                offeringTitle,
                offerDescription,
                offerImage,
                offeringUrl,
              } = offering || {};

              let image = getImage(offerImage?.localFile);
              return (
                <div className="item-container" key={offeringIndex}>
                  <div className="inner-wrap">
                    <Link to={offeringUrl}>
                      <div className="image-container">
                        <GatsbyImage
                          alt={offeringTitle + " image"}
                          image={image}
                        />
                        <div className="overlay">
                          <div className="image-title">
                            <h2>{offeringTitle}</h2>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div
                      className="content-container"
                      dangerouslySetInnerHTML={{ __html: offerDescription }}
                    ></div>
                  </div>
                </div>
              );
            })}
        </div>
      </Wrapper>
    </OfferingsContainer>
  );
};

export default Offerings;
