import React, { useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import About from "../components/page-components/about";
import { WebsiteContext } from "../context/WebsiteContext";

const AboutPageContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const AboutPage = ({ data }) => {
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title="About" />
      <AboutPageContainer distanceFromTop={distanceFromTop}>
        <About data={data} />
      </AboutPageContainer>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query {
    about: allWpPage(filter: { title: { eq: "About" } }) {
      edges {
        node {
          id
          about_acf {
            firstContentSection {
              sectionTitle
              sectionDescription
            }
            # leadershipSection {
            #   leadershipContent
            #   sectionTitle
            #   leadershipProfiles {
            #     profileName
            #     profileImage {
            #       localFile {
            #         childImageSharp {
            #           gatsbyImageData(
            #             quality: 90
            #             placeholder: BLURRED
            #             formats: [AUTO, WEBP, AVIF]
            #             layout: FULL_WIDTH
            #           )
            #         }
            #       }
            #     }
            #     email
            #     linkedin
            #     profileDescription
            #   }
            # }
            whatWeOfferSection {
              sectionTitle
              sectionDescription
              offerings {
                offerDescription
                offeringTitle
                offeringUrl
                offerImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
